<template>
  <v-dialog v-model='isOpen' max-width='778' width='100%'>
    <v-card class="px-6" :loading="loading">
      <div class="py-10">
        <v-card-title class="justify-center text-center d-flex flex-column pa-0 mb-6">
          <span class='card-header-title'>You may need to register to vote or update your voter registration.</span>
        </v-card-title>
        <v-card-text class="justify-center d-flex py-0 mb-6">
          <span class="card-body-text">Please go to the <a target="_blank" href="https://www.votetexas.gov/register-to-vote/update-voter-registration.html">Texas Secretary of State website</a> to verify or update your voter registration.</span>
        </v-card-text>
        <v-card-text class="py-0 mb-8">
          <div class="card-main-text">
            <p>Please note it can take up to 30 days to update your information with the state.</p>
          </div>

        </v-card-text>

        <v-card-actions class="d-flex flex-column justify-center pa-0">
          <v-btn
            class="ml-0 mb-4 "
            color="primary"
            outlined
            @click='changeERSStatus'
            :disabled="loading"
          >I've updated my registration with the Texas Secretary of State.</v-btn>
          <v-btn
            class="ml-0"
            color='primary'
            @click='remindLater'
            :disabled="loading"
          >
            Remind me next time
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "VerifyEmployeeRegistrationDialogStep4",
  data: () => ({
    isOpen: false,
    employeeId: '',
    employeeEmail: ''
  }),
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    changeERSStatus() {
      this.$emit('changeERSStatus')
    },
    remindLater() {
      this.$emit('remindLater')
    }
  },
  mounted() {
    this.isOpen = true
  }
}
</script>

<style scoped>

.card-header-title {
  font-size: 24px;
  font-weight: bold;
  width: 70%;
}

.card-body-text {
  font-size: 20px;
  font-weight: normal;
  color: #141414;
}

.card-main-text {
  color: #141414;
  font-size: 14px;
  font-weight: normal;
}


</style>